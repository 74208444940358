import React from 'react';
import {SingleEvent} from "../../types/SingleEvent.type";
import {ScheduleItem} from "../../types/ScheduleItem.type";
import './ScheduleItemCard.scss';
import './UpcomingItemGroup.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import ScheduleItemCard from "./index";

type ComponentProps = { event: SingleEvent, items: ScheduleItem[], title: string, origin: string }

export const ScreenUpcomingItemGroup: React.FC<ComponentProps> = ({event, items, title, origin}) => {
    return <div className="UpcomingItemGroup">
        <h2 className="UpcomingItemGroup_Title">{title}</h2>
            {items.map((item) => (
                <ScheduleItemCard
                    event={event}
                    {...item}
                    origin={origin}
                    key={item.token}
                />
            ))}
    </div>
}

export default ScreenUpcomingItemGroup;