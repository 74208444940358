import React from 'react';
import '../../scss/index.scss';
import PageWrapper from "../../components/PageWrapper";
import {ReactComponent as OopsImage} from '../../images/empty-state/oops.svg';
import './Error.scss';
import {Button} from "@mui/joy";
import RefreshIcon from "@mui/icons-material/Refresh";

export const Error: React.FC = () => {
    return <PageWrapper className="ErrorPage">

        <main>
            <div className="EmptyStateIcon">
                <OopsImage/>
            </div>

            <h2>Oeps</h2>
            <p>
                Er is iets fout gegaan.<br/>
                Probeer de app opnieuw te laden of te sluiten en weer opnieuw te openen.
            </p>

            <div className="Action">
                <Button component="a" href="/" variant="outlined" endDecorator={<RefreshIcon/>}>
                    App opnieuw laden
                </Button>
            </div>

        </main>
    </PageWrapper>;
}

export default Error;