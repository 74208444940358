import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
import {Page} from "../../../types/Page.type";
import List from '@mui/joy/List';
import PageListItem from "../../../components/PageListItem";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import {ListDivider} from "@mui/joy";

interface PagesProps extends EventContext {}

export const Pages: React.FC<PagesProps> = ({ event, pages, schedule }) => {
    const pageMap = new Map<string, Page>();

    pages.forEach((page: Page) => {
        page.children = []; // Initialize children array
        pageMap.set(page.token, page);
    });

    pages.forEach((page: Page) => {
        if (page.parent) {
            const parent = pageMap.get(page.parent);
            parent?.children?.push(page); // Add to parent's children array if parent exists
        }
    });

    const parentPages = pages.filter((page: Page) => {
        return page.parent === null && page.inMenu
    }).sort((a, b) => {
        const aChildren = a.children && a.children.length > 0;
        const bChildren = b.children && b.children.length > 0;

        const compareChildren = (aChildren === bChildren) ? 0 : aChildren? 1 : -1;
        const comparePosition = a.html.localeCompare(b.html);

        return compareChildren || comparePosition;
    });

    return <IonPage>
        <PageWrapper>
            <main className="Pages">
                {parentPages.map((page: Page) => {
                    const children = (page.children !== undefined && page.children.length > 0) ? page.children.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) : [];

                    return <div key={page.token}>
                        {(! page.isNagivatable) && <>
                            <PageListItem key={page.token} event={event} page={page} />
                        </>}

                        <List
                            sx={{
                                boxShadow: 'md',
                                marginBottom: 2,
                                borderRadius: 'sm',
                            }}
                            className="PageListGroup"
                        >
                            {(page.isNagivatable) && <>
                                <PageListItem key={page.token} event={event} page={page} />
                            </>}

                            {children.map((child: Page, index: number) => {
                                return <React.Fragment key={child.token}>
                                    <PageListItem event={event} page={child} />
                                    {index !== (children.length - 1) && <ListDivider inset="gutter"/>}
                                </React.Fragment>;
                            })}
                        </List>
                    </div>
                })}
            </main>
        </PageWrapper>
    </IonPage>;
}

export default Pages;