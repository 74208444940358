import {Event as TEvent, EventWrapperProps} from "react-big-calendar";
import React, {FC} from "react";
import classNames from "classnames";
import tinycolor from "tinycolor2";
import {moment} from "../../moment";

interface Event extends TEvent {
    location: {
        id: string,
        title: string,
    },
    tag: {
        slug: string,
        title: string,
        color: string,
    }
}

export const CustomEvent: FC<EventWrapperProps<Event>> = ({
                                                       event,
                                                       style,
    selected,
                                                       accessors,
                                                              onClick,
                                                       ...props }) => {

    return <div
        style={{
            ...style,
            top: `${style && style.top}%`,
            left: `${style && style.left}%`

    }}
        className={classNames({
            'rbc-event': true,
            'rbc-selected': selected,
            'rbc-event-allday': event.allDay,
        })}
        onClick={onClick}
    >
        <div className="Event">
            {event.tag && <div className="Event_Bar">
                <div style={{
                backgroundColor: tinycolor(event.tag.color).lighten(20).toString(),
                }}></div>
                </div>}

            <div className="Event_Time">
                {moment(event.start).format(('H:mm'))} - {moment(event.end).format(('H:mm'))}
            </div>


            <div className="Event_Name">
                {event.title}
            </div>

            <div className="Event_Location">
                {event.location.title}
            </div>

            {event.tag && <div className="Event_Tag" style={{
                backgroundColor: tinycolor(event.tag.color).lighten(20).toString(),
                color: tinycolor(event.tag.color).darken(30).toString()
            }}>
                {event.tag.title}
            </div>}
        </div>
    </div>;
}

export default CustomEvent;