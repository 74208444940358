import noAuthAxios from 'axios';
import authAxios from './auth-axios';

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';
export const METHOD_DELETE = 'delete';

export const handleApiCall = (
    path: string,
    method: string,
    authenticationNeeded: boolean,
    data: any,
    config?: object,
  ) => {
    const host = 'https://office.soulsurvivor.nl';
    const axios = authenticationNeeded ? authAxios : noAuthAxios;

    const axiosConfig = {
      ...config,
        method,
      params: undefined,
      data: undefined,
      url: path,
      baseURL: `${host}/api`,
    };

    if (method === METHOD_GET) {
        axiosConfig.params = data;
    } else if (method === METHOD_POST || method === METHOD_PUT) {
        axiosConfig.data = data;
    }

    return axios(axiosConfig);
};

export const handleInternalApiCall =  (
    path: string,
    method: string,
    authenticationNeeded: boolean,
    data: any,
    config?: object
) => {
  return handleApiCall(
      `/internal/${path}`,
      method,
      authenticationNeeded,
      data,
      config
  );
};
