import * as Sentry from '@sentry/browser';
import { getEnvironment } from '../util';

export const logError = (error: any) => {
  const environment = getEnvironment();

  if (environment === 'prod') {
    Sentry.withScope((scope) => {
      scope.setExtra('error', error);
      Sentry.captureException(error);
    });
  } else if (environment === 'dev') {
    console.error(error);
  }
};