import {useQuery} from "@tanstack/react-query";
import {handleInternalApiCall, METHOD_GET} from "../services/api-requests/ApiCallHandler";
import {SingleTicket} from "../types/SingleTicket.type";

type TicketList = SingleTicket[];

export const QUERY_KEY = 'orderTickets';

const fetchOrderTickets = async (eventToken: string): Promise<TicketList> => {
    const response = await handleInternalApiCall(
        'me/orders/tickets',
        METHOD_GET,
        true,
        null
    );

    return response.data[eventToken] ?? [];
};


export const useGetOrderTickets = (eventToken: string) => {
    return useQuery<TicketList, Error>(useGetOrderTicketsQuery(eventToken));
};

export const useGetOrderTicketsQuery = (eventToken: string, enabled?: boolean) => {
    return {
        queryKey: [`${eventToken}_${QUERY_KEY}`],
        queryFn: () => fetchOrderTickets(eventToken),
        enabled: !!enabled,
        staleTime: 7 * 24 * 60 * (60 * 1000), // 7 days
        cacheTime: 10 * 24 * 60 * (60 * 1000), // 10 days
    }
};