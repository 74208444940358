import {useQuery} from "@tanstack/react-query";
import {SingleEvent} from "../types/SingleEvent.type";
import {handleInternalApiCall, METHOD_GET} from "../services/api-requests/ApiCallHandler";

type EventList = SingleEvent[];

const QUERY_KEY = 'events';

const fetchEvents = async (): Promise<EventList> => {
    const response = await handleInternalApiCall(
        'shop/events',
        METHOD_GET,
        false,
        null
    );

    return response.data.events;
};

export const useGetEvents = () => {
    return useQuery<EventList, Error>({
        queryKey: [QUERY_KEY],
        queryFn: fetchEvents
    });
};