import React from 'react';
import './SideNavigation.scss'
import {NavLink} from "react-router-dom";
import {NavigationItem} from "./BottomNavigation";
import {ReactComponent as Logo} from '../../images/logo.svg';

type SideNavigationProps = {
    items: NavigationItem[],
}

export const SideNavigation = ({items}: SideNavigationProps) => {
    return <nav className="SideNavigation">
        <div className="Logo">
            <Logo />
        </div>
        <ul>
            {items.map((item) => {
                return <li key={item.id}>
                    <NavLink to={item.link} isActive={item.isActive}>
                        <span className="NavIcon">{item.icon}</span>
                        <span className="NavTitle">{item.title}</span>
                    </NavLink>
                </li>
            })}
        </ul>
    </nav>
}

export default SideNavigation;
