import React from 'react'
import moment from 'moment'
import * as dates from 'date-arithmetic'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import {SingleEvent} from "../../types/SingleEvent.type";

class ShortWeek extends React.Component {
    render() {
        let { event } = this.props
        let range = ShortWeek.range(event);

        return <TimeGrid
            {...this.props}
            range={range}
            // eventOffset={15}
            resources={undefined}
        />
    }
}

ShortWeek.range = (event: SingleEvent) => {
    let start = dates.startOf(moment(event.startDateTime).toDate(), 'day')
    let end = dates.startOf(moment(event.endDateTime).toDate(), 'day')

    let current = start
    let range = []

    while (dates.lte(current, end, 'day')) {
        range.push(current)
        current = dates.add(current, 1, 'day')
    }

    return range
}

ShortWeek.navigate = (date, action) => {
    return date;
}

ShortWeek.title = (date, { localizer }) => {
    return '';
}

export default ShortWeek