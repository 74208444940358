import React from 'react';
import './DateNavigation.scss'
import {Moment} from 'moment/moment';
import {SingleEvent} from "../../types/SingleEvent.type";
import {useSwiperReactive} from "../../services/useSwiperReactive";

type DateNavigationProps = {
    event: SingleEvent,
    dates: Moment[],
}

export const DateNavigation = ({ event, dates }: DateNavigationProps) => {
    const swiper = useSwiperReactive();

    return <nav className="DateNavigation InlineDateNavigation">
        <ul>
            {dates.map((item, index) => {
                const date = item.format('YYYY-MM-DD');

                return <li key={date}>
                    <button
                       className={swiper.activeIndex === index ? 'active' : ''}
                       onClick={(e) => {
                           e.preventDefault();
                           swiper.slideTo(index);
                       }}
                    >
                        <span className="DateNavigation_Day">{item.format('dd')}</span>
                    </button>
                </li>
            })}
        </ul>
    </nav>
}

export default DateNavigation;