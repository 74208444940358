import React from 'react';
import '../../scss/index.scss';
import {EventContext} from "./index";
import {getOngoingEvents, getUpcomingEventsForNextHours} from "../../helper/ScheduleHelper";
import UpcomingItemGroup from "../../components/ScheduleItemCard/UpcomingItemGroup";
import PageHeader from "../../components/PageHeader";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../components/PageWrapper";
import EmptyItemGroup from "../../components/ScheduleItemCard/EmptyItemGroup";

interface UpcomingProps extends EventContext {}

export const Upcoming: React.FC<UpcomingProps> = ({ event, pages, schedule }) => {
    const ongoingEvents = getOngoingEvents(schedule);
    const upcomingEvents = getUpcomingEventsForNextHours(schedule);

    return <IonPage>
        <PageWrapper>
            <PageHeader
                backgroundImage="https://site.assets.soulsurvivor.nl/uploads/2024/04/21120442/app-header-1.jpg"
                title={event.name}
            />

            <main>
                {ongoingEvents.length + upcomingEvents.length === 0 && <div>
                    <EmptyItemGroup event={event} />
                </div>}
                {ongoingEvents.length > 0 && <div>
                    <UpcomingItemGroup
                        title="Nu bezig"
                        event={event}
                        items={ongoingEvents}
                        origin={`/${event.slug}/upcoming`}
                    />
                </div>}

                {upcomingEvents.length > 0 && <div>
                    <UpcomingItemGroup
                        title="Straks"
                        event={event}
                        items={upcomingEvents}
                        origin={`/${event.slug}/upcoming`}
                    />
                </div>}
            </main>
        </PageWrapper>
    </IonPage>;
}

export default Upcoming;