export const theme = {
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: "#9659e1",
                    50: "#f1e8fb",
                    100: "#dac7f4",
                    200: "#c2a1ee",
                    300: "#aa79e7",
                    400: "#9659e1",
                    500: "#8238da",
                    600: "#7832d4",
                    700: "#6a29cb",
                    800: "#5d22c4",
                    900: "#4813b6",
                },
            },
        },
    },
    fontFamily: {
        body: '"Aeonik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif', // applies to `h1`–`h4`
        display: '"Martin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif', // applies to `title-*` and `body-*`
    },
    focus: {
        default: {
            outlineWidth: '2px',
            outlineOffset: '2px',
            outlineColor: '#339af0',
        },
    },
    letterSpacing: 0,
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '1rem',
            }
        }
    }
};

export default theme;