import React from 'react';
import {SingleEvent} from "../../types/SingleEvent.type";
import {Schedule} from "../../types/Schedule.type";
import 'swiper/css';
import 'swiper/css/pagination';
import {Alert, Button} from "@mui/joy";

type InlineAlertProps = {
    event: SingleEvent,
    schedule: Schedule,
    content?: string,
    action?: string,
    link?: string,
}

export const InlineAlert = ({ event, link, action, content }: InlineAlertProps) => {
    return <Alert
        variant="outlined"
        className="InlineBanner"
        color="neutral"
        endDecorator={(action && link) && (
            <Button component="a" href={link} target="_blank">
                {action}
            </Button>
        )}
    >
        {content && content}
    </Alert>;
}

export default InlineAlert;