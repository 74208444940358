import React from 'react';
import {SingleEvent} from "../../types/SingleEvent.type";
import {Schedule} from "../../types/Schedule.type";
import 'swiper/css';
import 'swiper/css/pagination';
import {Button} from "@mui/joy";
import Card from "@mui/joy/Card";

type InlineCardProps = {
    event: SingleEvent,
    schedule: Schedule,
    title?: string,
    content?: string,
    action?: string,
    link?: string,
}

export const InlineCard = ({ event, title, content, action, link }: InlineCardProps) => {
    return <Card
        className="InlineCard"
        variant="plain"
        sx={{
            boxShadow: 'md',
            marginBottom: '10px'
        }}>
        {title && <h2>{title}</h2>}
        {content && <p>
            {content}
        </p>}
        {(action && link) && <p>
            <Button component="a" href={link} target="_blank">
                {action}
            </Button>
        </p>}
    </Card>
}

export default InlineCard;