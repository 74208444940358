import React from 'react';
import '../../../scss/index.scss';
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import PageHeader from "../../../components/PageHeader";
import {ProfileContext} from "./index";
import TicketList from "../../../components/Tickets/TicketList";
import {FormControl, FormLabel, Switch} from '@mui/joy';
import {QUERY_KEY as TICKETS_KEY} from "../../../hooks/useGetTickets";
import {Query, useQueryClient} from "@tanstack/react-query";
import {QUERY_KEY as ORDERS_KEY} from "../../../hooks/useGetOrders";
import {QUERY_KEY as ORDER_TICKETS_KEY} from "../../../hooks/useGetOrderTickets";

interface TicketsProps extends ProfileContext {
    isFetching: boolean
}

export const Tickets: React.FC<TicketsProps> = ({isFetching, tickets, orderTickets, event, pages, schedule}) => {
    const [checked, setChecked] = React.useState<boolean>(true);

    const ticketList = checked ? tickets : orderTickets;

    const queryClient = useQueryClient();

    return <IonPage>
        <PageWrapper>
            <PageHeader
                small
                title="Mijn tickets"
                backLink={`/${event.slug}/profile`}
                backgroundImage="https://site.assets.soulsurvivor.nl/uploads/2024/04/21120442/app-header-1.jpg"
                refreshAction={() => {
                    const keys = [
                        `${event.token}_${ORDERS_KEY}`,
                        `${event.token}_${TICKETS_KEY}`,
                        `${event.token}_${ORDER_TICKETS_KEY}`
                    ];

                    queryClient.invalidateQueries({
                        predicate: (query: Query) => {
                            return keys.includes(query.queryKey[0] as string);
                        }})
                }}
                disableRefresh={isFetching}
            />
            <main>
                <FormControl
                    orientation="horizontal"
                    sx={{ width: '100%', justifyContent: 'space-between' }}
                >
                    <div>
                        <FormLabel>Toon alleen tickets die op mijn naam staan</FormLabel>
                    </div>
                    <Switch
                        checked={checked}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setChecked(event.target.checked)
                        }
                        color={checked ? 'success' : 'neutral'}
                        variant={checked ? 'solid' : 'outlined'}
                        slotProps={{
                            endDecorator: {
                                sx: {
                                    minWidth: 24,
                                },
                            },
                        }}
                    />
                </FormControl>

                <TicketList
                    event={event}
                    tickets={ticketList}
                />
            </main>
        </PageWrapper>
    </IonPage>;
}

export default Tickets;