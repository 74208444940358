import React from 'react';
import '../../../scss/index.scss';
import './InfoDisplay.scss';
import {EventContext} from "../index";
import {getOngoingEvents, getUpcomingEventsForNextHours} from "../../../helper/ScheduleHelper";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import EmptyItemGroup from "../../../components/ScheduleItemCard/EmptyItemGroup";
import {ReactComponent as Logo} from '../../../images/logo.svg';
import ScreenUpcomingItemGroup from "../../../components/ScheduleItemCard/ScreenUpcomingItemGroup";
import QRCode from "react-qr-code";

interface InfoDisplayProps extends EventContext {}

export const InfoDisplay: React.FC<InfoDisplayProps> = ({ event, pages, schedule }) => {
    const ongoingEvents = getOngoingEvents(schedule);
    const upcomingEvents = getUpcomingEventsForNextHours(schedule);

    window.setTimeout(function(){
        window.location.reload();
    },900000);

    return <IonPage>
        <PageWrapper className="InfoDisplay">
            <div className="Logo">
                <Logo/>
            </div>

            <main>

                <div className="CTA">
                    <div className="Text">
                        Het volledige programma vind je <br /> in onze app via <br />
                        <strong>soulsurvivor.nl/programma</strong>
                    </div>

                    <div className="QR">
                        <QRCode value="https://soulsurvivor.nl/programma?mtm_campaign=Festival-2024&mtm_source=info&mtm_medium=display" />
                    </div>
                </div>

                <div className="Program">
                    {ongoingEvents.length + upcomingEvents.length === 0 && <div>
                        <EmptyItemGroup event={event}/>
                    </div>}
                    {ongoingEvents.length > 0 && <div>
                        <ScreenUpcomingItemGroup
                            title="Nu bezig"
                            event={event}
                            items={ongoingEvents}
                            origin={`/${event.slug}/upcoming`}
                        />
                    </div>}

                    {upcomingEvents.length > 0 && <div>
                        <ScreenUpcomingItemGroup
                            title="Straks"
                            event={event}
                            items={upcomingEvents}
                            origin={`/${event.slug}/upcoming`}
                        />
                    </div>}
                </div>
            </main>
        </PageWrapper>
    </IonPage>;
}

export default InfoDisplay;