// import Icon from "@material-ui/core/Icon";
import * as React from "react";
import 'material-symbols';
import {Page} from "../../types/Page.type";
import {ListItemButton, ListItemContent, ListItemDecorator} from "@mui/joy";
import {Link} from "react-router-dom";
import IconPresentation from "../IconPresentation";
import {KeyboardArrowRight} from "@mui/icons-material";
import ListItem from "@mui/joy/ListItem";
import {SingleEvent} from "../../types/SingleEvent.type";
import './PageListItem.scss'
import {ReactComponent as Soul} from '../../images/soul.svg';

type PageListItemProps = {
    event: SingleEvent,
    page: Page
}

const PageListItem = ({ event, page }: PageListItemProps) => {
    if (page.isNagivatable) {
        return <ListItem className="PageListItem">
            <ListItemButton
                component={Link}
                to={`/${event.slug}/info/${page.slug}`}
            >
                {page.icon && <ListItemDecorator className="PageListItem_Icon">
                    {page.icon === 'logo' ? <Soul/> : <IconPresentation glyph={page.icon}/>}
                </ListItemDecorator>}
                <ListItemContent>
                    {page.name}
                </ListItemContent>
                <KeyboardArrowRight/>
            </ListItemButton>
        </ListItem>
    } else {
        return <ListItem className="PageListItem PageListItemHeader">
            <ListItemContent>
                {page.name}
            </ListItemContent>
        </ListItem>
    }
}

export default PageListItem;