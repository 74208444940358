import React from 'react';
import moment from 'moment/moment';
import {SingleEvent} from "../../types/SingleEvent.type";
import {
    getItemsByHost,
    getItemsByLocation,
    getItemsByTag,
    getSelectedDate,
    sortDates
} from "../../helper/ScheduleHelper";
import InlineDateNavigation from "../DateNavigation/InlineDateNavigation";
import {ScheduleItem} from "../../types/ScheduleItem.type";
import ScheduleItemCard from "../ScheduleItemCard";
import {Schedule} from "../../types/Schedule.type";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

type InlineScheduleProps = {
    event: SingleEvent,
    schedule: Schedule,
    location?: string,
    tag?: string,
    host?: string,
    title?: string,
}

export const InlineSchedule = ({event, schedule, location, tag, host, title}: InlineScheduleProps) => {
    let scheduleItems = schedule;

    if (location) {
        scheduleItems = getItemsByLocation(schedule, location);
    }

    if (tag) {
        scheduleItems = getItemsByTag(schedule, tag);
    }

    if (host) {
        scheduleItems = getItemsByHost(schedule, host);
    }

    const dates = sortDates(Object.keys(scheduleItems).map((date) => moment(date)));

    const selectedDate = getSelectedDate(event, dates);

    const initialSlide = dates.findIndex((item) => {
        return item.format('YYYY-MM-DD') === selectedDate
    });

    return <div className="InlineSchedule">
        <h2>{title ? title : 'Programma' }</h2>

        <Swiper
            autoHeight
            spaceBetween={30}
            slidesPerView={1}
            className="UpcomingItemList"
            initialSlide={initialSlide}
        >
            <InlineDateNavigation event={event} dates={dates}/>

            {dates.map((momentDate) => {
                const date = momentDate.format('YYYY-MM-DD');

                return <SwiperSlide key={date}>
                    {scheduleItems[date].map((item: ScheduleItem) => (
                        <ScheduleItemCard
                            inline
                            event={event}
                            origin={window.location.pathname}
                            {...item}
                        />)
                    )}
                </SwiperSlide>
            })}
        </Swiper>
    </div>;
}

export default InlineSchedule;