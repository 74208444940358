import React from 'react';
import '../../scss/index.scss';
import {useGetEvents} from "../../hooks/useGetEvents";
import {FullScreenLoader} from "../../components/FullScreenLoader";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Moment from "react-moment";
import {Redirect} from "react-router";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../components/PageWrapper";
import Error from "../Error";

export const Events = () => {
    const {
        data: events,
        isLoading: isEventsLoading,
        isError: isEventsError
    } = useGetEvents();

    if (isEventsLoading) return (<FullScreenLoader/>)
    if (isEventsError) return (<Error />)

    if(events === undefined) {
        return <div></div>;
    }

    if(events.length === 1) {
        return <Redirect to={`/${events[0].slug}`} />;
    }

    return <IonPage>
        <PageWrapper>
            <main>
                <Typography level="h1" sx={{ mb: 0.5 }}>Events</Typography>

                {events.map((event) => {
                    return <Card sx={{ boxShadow: 'lg', gap: 0, paddingTop: 0, paddingBottom: 0, overflow: 'hidden' }}>
                        <Link href={`${event.slug}`} overlay underline="none" sx={{
                            flexDirection: 'column'
                        }}>
                        <CardOverflow sx={{ padding: 0 }}>
                            <img
                                src={event.heroImage}
                                loading="lazy"
                                alt=""
                            />
                        </CardOverflow>
                        <CardOverflow variant="soft">
                            <CardContent orientation="vertical">
                                <Typography level="body-xs">
                                    <Moment date={event.startDateTime} locale="nl" format="D MMM YYYY" />
                                    {event.startDateTime !== event.endDateTime && (
                                        <>
                                            {' - '}
                                            <Moment
                                                date={event.endDateTime}
                                                locale="nl"
                                                format="D MMM YYYY"
                                            />
                                        </>
                                    )}
                                </Typography>
                                <Divider orientation="vertical" />
                                <Typography level="body-xs">{event.location}</Typography>
                            </CardContent>
                        </CardOverflow>
                        </Link>
                    </Card>
                })}
            </main>
        </PageWrapper>
    </IonPage>
}

export default Events;