import React from 'react';
import './FullScreenLoader.scss'
import {LinearProgress} from "@mui/joy";
import {ReactComponent as Soul} from '../../images/soul.svg';

export const FullScreenLoader = () => {
    return <div className="FullScreenLoader">
        <div className="content">
            <Soul />
            <LinearProgress />
        </div>
    </div>
}

export default FullScreenLoader;