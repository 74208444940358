import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Alert, Button, Card, FormControl, FormHelperText, FormLabel, Input, Stack,} from "@mui/joy";
import {Formik} from 'formik';
import {handleApiCall, METHOD_POST} from "../../services/api-requests/ApiCallHandler";
import {FormikHelpers, FormikValues} from "formik/dist/types";
import LocalStorageService from "../../services/storage/LocalStorageService";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {AxiosError} from "axios";
import {ApiError} from "../../types/ApiError.type";
import {logInUserByUsernameAndPassword} from "../../helper/SessionHelper";
import * as Yup from 'yup';
import {InfoOutlined} from "@mui/icons-material";
import './LoginForm.scss';
import queryString from "query-string";
import Divider from "@mui/joy/Divider";

type LoginFormState = {
    validation: any,
    checkedMethod: boolean,
    submitDisabled: boolean,
    error: string | null,
    emailSent: boolean,
    loggedIn: boolean,
}

type InitialFormState = {
    username: string,
    password: string,
    requiresPassword: boolean,
}

type LoginFormProps = {
    eventSlug: string,
    redirectUrl: string,
}

class LoginForm extends Component<LoginFormProps, LoginFormState> {
    state: LoginFormState = {
        validation: {},
        checkedMethod: false,
        submitDisabled: false,
        error: null,
        emailSent: false,
        loggedIn: false,
    };

    handleError = (error: AxiosError<ApiError>) => {
        if ((error.response && error.response.status === 400)
            || (error.response && error.response.status === 401)) {
            if (error.response.data.fields !== undefined) {
                this.setState({
                    validation: error.response.data.fields,
                });
            }
            if (error.response.data.message !== undefined) {
                this.setState({
                    error: error.response.data.message,
                })
            }
        }
    };

    checkLoginMethod = (email: string, onCheck: (requiresPassword: boolean) => void) => {
        handleApiCall('/login/method', METHOD_POST, false, {
            email,
        }).then((response) => {
            const hasEmail = response.data.includes('EMAIL');
            const hasPassword = response.data.includes('PASSWORD');

            if (hasEmail && !hasPassword) {
                this.setState({
                    error: '',
                });

                this.sendEmail(email);

                onCheck(false);
            } else if (hasEmail && hasPassword) {
                this.setState({
                    submitDisabled: false,
                    checkedMethod: true,
                    error: '',
                });

                onCheck(true);
            } else {
                this.setState({
                    error: "We konden je niet inloggen, controleer je e-mailadres en probeer het opnieuw.",
                    submitDisabled: false,
                });
            }
        }).catch((error) => {
            this.handleError(error);

            this.setState({
                submitDisabled: false,
            });
        });
    };

    sendEmail = (email: string) => {
        const {redirectUrl} = this.props;
        handleApiCall('/login/key/request', METHOD_POST, false, {
            email,
            redirectUrl,
        }).then(() => {
            this.setState({
                emailSent: true
            })
        }).catch((error) => {
            this.handleError(error);
        });
    };

    handleSubmit = (values: FormikValues, formikHelpers: FormikHelpers<InitialFormState>) => {
        const {checkedMethod} = this.state;
        const {eventSlug} = this.props;

        this.setState({
            submitDisabled: true,
        });

        if (!checkedMethod) {
            this.checkLoginMethod(values.username, (requiresPassword) => {
                formikHelpers.setFieldValue('requiresPassword', requiresPassword);
            });
        } else {
            logInUserByUsernameAndPassword(values.username, values.password, eventSlug, () => {
                this.setState({
                    loggedIn: true,
                })
            }, (error) => {
                if (error.response && error.response.status === 401
                    && error.response.data.message !== undefined) {
                    this.setState({
                        error: error.response.data.message,
                    })
                } else {
                    this.setState({
                        error: "We konden je niet inloggen, controleer je e-mailadres en probeer het opnieuw."
                    })
                }

                this.setState({
                    submitDisabled: false,
                });
            });
        }
    };

    render() {
        const {
            submitDisabled, error, checkedMethod, loggedIn, emailSent
        } = this.state;
        const {redirectUrl} = this.props;

        if (LocalStorageService.isLoggedIn()) {
            return <Redirect to={redirectUrl} />;
        }

        const schema = Yup.object().shape({
            username: Yup.string().email('Vul een geldig e-mailadres in').required('Vul een e-mailadres in'),
            requiresPassword: Yup.boolean(),
            password: Yup.string().when('requiresPassword', {
                is: true,
                then: () => Yup.string().required('Vul een wachtwoord in'),
                otherwise: () => Yup.string()
            }),
        });

        if (loggedIn) {
            return <Redirect to={redirectUrl}/>;
        }

        if (emailSent) {
            return <div className="LoginForm">
                <Card
                    variant="plain"
                    sx={{
                        boxShadow: 'md',
                        marginBottom: '10px'
                    }}>
                    <h2>You've got mail!</h2>
                    <p>
                        Binnen enkele ogenblikken ontvang je een email met een link om in te loggen.
                    </p>
                </Card>
            </div>
        }

        const urlParams = queryString.parse(window.location.search);
        let sessionError: string;

        if (typeof urlParams.e !== 'undefined' && !emailSent) {
            if (urlParams.e === 'session_expired') {
                sessionError = "Je sessie is verlopen, log opnieuw in.";
            } else if (urlParams.e === 'key_expired') {
                sessionError = "De link om in te loggen is verlopen, log opnieuw in.";
            } else if (urlParams.e === 'key_invalid') {
                sessionError = "De link om in te loggen is ongeldig, log opnieuw.";
            }
        }

        return (
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                    requiresPassword: false,
                }}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <div className="LoginForm">
                        <p>
                            Vul het e-mailadres in waarmee je je tickets hebt besteld.
                        </p>

                        <Card
                            variant="plain"
                            sx={{
                                boxShadow: 'md',
                                marginBottom: '10px'
                            }}>
                            <form onSubmit={handleSubmit}>
                                {(error || sessionError) && <div>
                                    {error && <Alert color="danger">{error}</Alert>}
                                    {sessionError && <Alert color="danger">{sessionError}</Alert>}
                                    <Divider sx={{
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}/>
                                </div>}
                                <FormControl error={!!(errors.username && touched.username)}>
                                    <FormLabel>E-mailadres</FormLabel>
                                    <Input
                                        placeholder="E-mailadres"
                                        name="username"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.username}
                                        autoFocus={!checkedMethod}
                                        type="email"
                                    />
                                    {errors.username && touched.username ? (
                                        <FormHelperText>
                                            <InfoOutlined/>
                                            {errors.username}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                                {values.requiresPassword ? (
                                    <div>
                                        <FormControl error={!!(errors.password && touched.password)}
                                                     sx={{marginTop: 2}}>
                                            <FormLabel>Wachtwoord</FormLabel>
                                            <Input
                                                placeholder="Wachtwoord"
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                type="password"
                                                autoFocus={checkedMethod}
                                            />
                                            {errors.password && touched.password ? (
                                                <FormHelperText>
                                                    <InfoOutlined/>
                                                    {errors.password}
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>

                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            sx={{marginTop: 2}}
                                        >
                                            <Button variant="plain" onClick={() => {
                                                this.sendEmail(values.username);
                                            }}>
                                                <EmailOutlinedIcon/>&nbsp; Email een login-link
                                            </Button>
                                            <div className="ButtonRight">
                                                <Button disabled={submitDisabled} type="submit">
                                                    Inloggen
                                                </Button>
                                            </div>
                                        </Stack>
                                    </div>
                                ) : (
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        sx={{marginTop: 2}}
                                    >
                                        <Button disabled={submitDisabled} type="submit">
                                            Doorgaan
                                        </Button>
                                    </Stack>
                                )}
                            </form>
                        </Card>
                    </div>
                )}
            </Formik>
        );
    }
}

export default LoginForm;