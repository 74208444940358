import {useQuery} from "@tanstack/react-query";
import {handleInternalApiCall, METHOD_GET} from "../services/api-requests/ApiCallHandler";
import {OrderListItem} from "../types/SingleOrder.type";

type OrderList = OrderListItem[];

export const QUERY_KEY = 'orders';

const fetchOrders = async (eventToken: string): Promise<OrderList> => {
    const response = await handleInternalApiCall(
        'me/orders',
        METHOD_GET,
        true,
        null
    );

    return response.data.filter((order: OrderListItem) => order.event.token === eventToken);
};


export const useGetOrders = (eventToken: string) => {
    return useQuery<OrderList, Error>(useGetOrdersQuery(eventToken));
};

export const useGetOrdersQuery = (eventToken: string, enabled?: boolean) => {
    return {
        queryKey: [`${eventToken}_${QUERY_KEY}`],
        queryFn: () => fetchOrders(eventToken),
        enabled: !!enabled,
        staleTime: 7 * 24 * 60 * (60 * 1000), // 7 days
        cacheTime: 10 * 24 * 60 * (60 * 1000), // 10 days
    }
};