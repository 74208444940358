import React from 'react';
import '../../scss/index.scss';
import {EventContext} from "./index";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../components/PageWrapper";
import {Redirect} from "react-router-dom";
import LocalStorageService from "../../services/storage/LocalStorageService";
import LoginForm from "../../components/Form/LoginForm";
import PageHeader from "../../components/PageHeader";

interface LoginProps extends EventContext {
}

export const Login: React.FC<LoginProps> = ({event, pages, schedule}) => {
    const isLoggedIn = LocalStorageService.isLoggedIn();

    if (isLoggedIn) {
        return <Redirect to={`/${event.slug}/profile`}/>;
    }

    return <IonPage>
        <PageWrapper>
            <PageHeader
                backgroundImage="https://site.assets.soulsurvivor.nl/uploads/2023/12/12135253/card_info-scaled.jpg"
                title="Log in"
            ></PageHeader>
            <main>
                <LoginForm
                    eventSlug={event.slug}
                    redirectUrl={`${window.location.origin}/${event.slug}/profile`}
                />
            </main>
        </PageWrapper>
    </IonPage>;
}

export default Login;