import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
import {RouteComponentProps} from "react-router";
import FullScreenLoader from "../../../components/FullScreenLoader";
import PageHeader from "../../../components/PageHeader";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import HtmlContent from "../../../components/HtmlContent";
import InlineSchedule from "../../../components/HtmlContent/InlineSchedule";
import Divider from "@mui/joy/Divider";
import Error from "../../Error";

interface HostProps extends EventContext, RouteComponentProps<{
    date: string;
    itemSlug: string;
    hostSlug: string;
}> {
}

export const Host: React.FC<HostProps> = ({event, pages, schedule, match}) => {
    const {date, itemSlug, hostSlug} = match.params;

    if (event === null || schedule === null || date === undefined || itemSlug === undefined || hostSlug === undefined) {
        return <FullScreenLoader />;
    }

    const items = schedule[date] ?? [];
    const item = items.find((item) => item.slug === itemSlug);
    const host = item?.hosts.find((host) => host.slug === hostSlug);

    if (host === undefined) {
        return <Error />;
    }

    return <IonPage>
        <PageWrapper>
            <PageHeader
                backgroundImage={host.image}
                backLink={`/${event.slug}/item/${date}/${itemSlug}`}
                title={host.name}
            />
            <main>
                <HtmlContent
                    content={host.description}
                    event={event}
                    pages={pages}
                    schedule={schedule}
                />
                <Divider sx={{
                    marginTop: 4,
                    marginBottom: 4,
                }}/>
                <InlineSchedule
                    event={event}
                    schedule={schedule}
                    host={host.token}
                />
            </main>
        </PageWrapper>
    </IonPage>;

// return<div className="SlideCard">
        // <Card
    //         // orientation="horizontal"
    //         variant="plain"
    //         sx={{
    //             boxShadow: 'md',
    //             width: '100%',
    //             // flexWrap: 'wrap',
    //             marginBottom: 2,
    //         }}
    //     >
    //         <div>
    //             <img
    //                 src={image}
    //                 loading="lazy"
    //                 alt=""
    //             />
    //         </div>
    //         <h2>
    //             {name}
    //         </h2>
    //         <p>
    //             {description}
    //         </p>
    //     </Card>
    // </div>
}

export default Host;