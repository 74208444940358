import React from 'react';
import Card from "@mui/joy/Card";
import ListItem from '@mui/joy/ListItem';
import {SingleTicket} from "../../types/SingleTicket.type";
import QRCode from "react-qr-code";
import './TicketCard.scss'
import moment from "moment";

type ComponentProps = SingleTicket;


export const TicketCard = ({...ticket}: ComponentProps) => {
    // const date = moment(item.dateTimeFrom.date).format('YYYY-MM-DD');
    return <ListItem className="TicketListItem">
        <Card
            // component={Link}
            className="TicketCard"
            // to={`/${event.slug}/item/${date}/${item.slug}`}
            variant="plain"
            sx={{
                boxShadow: 'md',
                marginBottom: '10px',
                // backgroundImage: `url(${item.image})`
            }}>
            <div className="TicketCard_Header">
                <div className="TicketCard_Number">
                    #{ticket.number}
                </div>
                <div className="TicketCard_Name">
                    {ticket.name}
                </div>
                <div className="TicketCard_Attendee">
                    {ticket.attendee.firstName}
                    {ticket.attendee.preposition && ` ${ticket.attendee.preposition}`}
                    {' '}{ticket.attendee.lastName}
                </div>
                <div className="ScheduleItemCard_Title">
                    <div className="ScheduleItemCard_Details">
                        <div className="ScheduleItemCard_Time">
                            {moment(ticket.validFrom).format(('DD-MM-YYYY'))} - {moment(ticket.validTill).format((('DD-MM-YYYY')))}
                        </div>
                    </div>
                </div>
                <div className="TicketCard_QR">
                    <div className="TicketCard_QR_Code">
                        <QRCode value={ticket.barcode}/>
                    </div>
                </div>
            </div>
        </Card>
    </ListItem>
}

export default TicketCard;