import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
// import {Navigate, Outlet, useParams} from "react-router-dom";
import {moment} from "../../../moment";
import {RouteComponentProps} from "react-router";
import {IonPage} from "@ionic/react";
import SchedulePage from "./SchedulePage";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import PageWrapper from '../../../components/PageWrapper';
import InlineDateNavigation from "../../../components/DateNavigation/InlineDateNavigation";
import {Modal, ModalClose, ModalDialog} from '@mui/joy';
import {Link} from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import {ScheduleItem as ScheduleItemProps} from "../../../types/ScheduleItem.type";
import HtmlContent from "../../../components/HtmlContent";

interface WebsiteScheduleProps extends EventContext, RouteComponentProps {
}

export const Schedule: React.FC<WebsiteScheduleProps> = ({event, pages, schedule, match}) => {
    const [selectedItem, setSelectedItem] = React.useState<ScheduleItemProps | null>(null);

    const range = moment.range(moment(event.startDateTime), moment(event.endDateTime));
    const dates = Array.from(range.by('day'));

    return <IonPage>
        <PageWrapper className="IsEmbedded">
            {selectedItem && <Modal
                open
                onClose={() => setSelectedItem(null)}
                className="WebsiteScheduleItem"
            >
                <ModalDialog
                    sx={{
                        padding: 0,
                        overflow: 'hidden',
                        gap: 0,
                    }}>
                    <PageHeader
                        small
                        backgroundImage={selectedItem.image}
                        title={selectedItem.name}
                        info={`${moment(selectedItem.dateTimeFrom.date).format(('dd H:mm'))} - ${moment(selectedItem.dateTimeTill.date).format(('H:mm'))}  •  ${selectedItem.location.name}`}
                    />
                    <HtmlContent
                        content={selectedItem.description}
                        event={event}
                        schedule={schedule}
                        pages={pages}
                    />
                    <ModalClose
                        className="Close"
                        component={Link}
                        to={{
                            pathname: `/${event.slug}/site/schedule/`,
                            search: '?embedded',
                        }}
                    />
                </ModalDialog>
            </Modal>}

            <Swiper
                autoHeight
                className="ScheduleSwiper IsEmbedded"
                spaceBetween={50}
            >
                <InlineDateNavigation
                    event={event}
                    dates={dates}
                />

                {dates.map((date, index) => {
                    return <SwiperSlide
                        key={date.format('YYYY-MM-DD')}
                        data-history={date.format('YYYY-MM-DD')}
                    >
                        <main className="SchedulePage">
                            <SchedulePage
                                event={event}
                                schedule={schedule}
                                pages={pages}
                                date={date.format('YYYY-MM-DD')}
                                onItemClick={setSelectedItem}
                            />
                        </main>
                    </SwiperSlide>
                })}
            </Swiper>
        </PageWrapper>
    </IonPage>;
}

export default Schedule;