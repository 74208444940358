import React, {createRef} from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
// import {Navigate, Outlet, useParams} from "react-router-dom";
import {DateNavigation} from "../../../components/DateNavigation";
import {moment} from "../../../moment";
import {RouteComponentProps} from "react-router";
import {IonPage} from "@ionic/react";
import SchedulePage from "./SchedulePage";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import PageWrapper from '../../../components/PageWrapper';
import {History} from 'swiper/modules';
import {getSelectedDate} from "../../../helper/ScheduleHelper";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

interface ScheduleProps extends EventContext, RouteComponentProps<{
    date?: string
}> {
}

export const Schedule: React.FC<ScheduleProps> = ({event, pages, schedule, match}) => {
    const {date} = match.params;

    const range = moment.range(moment(event.startDateTime), moment(event.endDateTime));
    const dates = Array.from(range.by('day'));
    const selectedDate = getSelectedDate(event, dates, date);

    const initialSlide = dates.findIndex((item) => {
        return item.format('YYYY-MM-DD') === selectedDate
    });

    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollToPlugin);

    return <IonPage>
        <PageWrapper>
            <Swiper
                className="ScheduleSwiper"
                spaceBetween={50}
                history={{
                    enabled: true,
                    root: `/${event.slug}/schedule/`,
                    key: '',
                }}
                modules={[History]}
                initialSlide={initialSlide}
            >
                <DateNavigation
                    event={event}
                    dates={dates}
                />

                {dates.map((date, index) => {
                    const container = createRef<HTMLDivElement>();

                    return <SwiperSlide
                        key={date.format('YYYY-MM-DD')}
                        data-history={date.format('YYYY-MM-DD')}
                    >
                        <main className="SchedulePage hasDateNavigation" ref={container}>
                            <SchedulePage
                                event={event}
                                schedule={schedule}
                                pages={pages}
                                date={date.format('YYYY-MM-DD')}
                                container={container}
                            />
                        </main>
                    </SwiperSlide>
                })}
            </Swiper>
        </PageWrapper>
    </IonPage>;
}

export default Schedule;