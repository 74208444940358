import React from 'react';
import {SingleEvent} from "../../types/SingleEvent.type";
import './TicketList.scss';
import {SingleTicket} from "../../types/SingleTicket.type";
import TicketCard from "./TicketCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

type ComponentProps = {
    event: SingleEvent,
    tickets: SingleTicket[],
}

export const TicketList = ({event, tickets}: ComponentProps) => {
    return <div className="TicketGroup">

        <Swiper
            autoHeight
            spaceBetween={30}
            slidesPerView={1}
            className="TicketList"
            pagination={{
                dynamicBullets: true,
            }}
            modules={[Pagination]}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            }}
        >
            {tickets.map((ticket) => (
                <SwiperSlide key={ticket.token}>
                    <TicketCard {...ticket} />
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
}

export default TicketList;