import React from 'react';
import {ReactComponent as Background} from '../../images/empty-state/bg.svg';
import './EmptyState.scss';
import classNames from "classnames";

type EmptyStateProps = {
    icon: React.ReactNode,
    title: string,
    children: React.ReactNode,
    action?: React.ReactNode
};

export const EmptyState: React.FC<EmptyStateProps> = ({icon, title, children, action}) => {
    return <div className={classNames({
        EmptyState: true,
        hasAction: !!action,
    })}>
        <div className="EmptyState_Icon">
            {icon}
            <div className="EmptyState_Background"><Background/></div>
        </div>

        <div className="EmptyState_Content">
            <h2>{title}</h2>
            <p>
                {children}
            </p>
        </div>

        {action && <div className="EmptyState_Action">
            {action}
        </div>}
    </div>
}

export default EmptyState;