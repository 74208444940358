import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import LocalStorageService from "../../../services/storage/LocalStorageService";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {Divider, ListItemButton, ListItemContent, ListItemDecorator} from "@mui/joy";
import {Link} from "react-router-dom";
import IconPresentation from "../../../components/IconPresentation";
import {KeyboardArrowRight} from "@mui/icons-material";
import PageHeader from "../../../components/PageHeader";
import {User} from "../../../types/User.type";
import {logout} from "../../../helper/SessionHelper";
import LoginPrompt from "../../../components/LoginPrompt";

interface ProfileMenuProps extends EventContext {
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({event, pages, schedule}) => {
    const isLoggedIn = LocalStorageService.isLoggedIn();

    if(isLoggedIn) {
        const user = LocalStorageService.getUser() as User;

        return <IonPage>
            <PageWrapper>
                <PageHeader
                    title={`${user.firstName}'s ${event.name}`}
                    backgroundImage="https://site.assets.soulsurvivor.nl/uploads/2024/04/06124827/52894426604_01c81519a7_k.jpg"
                />
                <main>
                    <List
                        sx={{
                            boxShadow: 'md',
                            marginBottom: '20px',
                            borderRadius: 'sm',
                        }}
                        className="PageListGroup"
                    >
                        <ListItem className="PageListItem">
                            <ListItemButton
                                component={Link}
                                to={`/${event.slug}/profile/tickets`}
                            >
                                <ListItemDecorator className="PageListItem_Icon">
                                    <IconPresentation glyph="ConfirmationNumberOutlined"/>
                                </ListItemDecorator>
                                <ListItemContent>
                                    Mijn tickets
                                </ListItemContent>
                                <KeyboardArrowRight/>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Divider />

                    <List
                        sx={{
                            boxShadow: 'md',
                            marginTop: '20px',
                            marginBottom: '10px',
                            borderRadius: 'sm',
                        }}
                        className="PageListGroup"
                    >
                        <ListItem className="PageListItem">
                            <ListItemButton
                                onClick={() => logout()}
                            >
                                <ListItemDecorator className="PageListItem_Icon">
                                    <IconPresentation glyph="Logout"/>
                                </ListItemDecorator>
                                <ListItemContent>
                                    Uitloggen
                                </ListItemContent>
                                <KeyboardArrowRight/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </main>
            </PageWrapper>
        </IonPage>;
    }

    return <IonPage>
        <PageWrapper>
            <PageHeader
                title={`Jouw ${event.name}`}
                backgroundImage="https://site.assets.soulsurvivor.nl/uploads/2024/04/06124827/52894426604_01c81519a7_k.jpg"
            />
            <main>
                <LoginPrompt event={event} />
            </main>
        </PageWrapper>
    </IonPage>;
}

export default ProfileMenu;