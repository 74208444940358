import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
import {FullScreenLoader} from "../../../components/FullScreenLoader";
import PageHeader from "../../../components/PageHeader";
import HtmlContent from "../../../components/HtmlContent";
import {RouteComponentProps} from "react-router";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import Error from "../../Error";

interface PageProps extends EventContext, RouteComponentProps<{
    pageSlug: string;
}> {}

export const Page: React.FC<PageProps> = ({ event, pages, schedule, match }) => {
    const { pageSlug } = match.params;

    if (pageSlug === undefined) {
        return <FullScreenLoader />;
    }

    const page = pages.find((page) => page.slug === pageSlug);

    if(page === undefined) {
        return <Error />;
    }

    return <IonPage>
        <PageWrapper>
            <PageHeader
                backgroundImage={page.image}
                backLink={`/${event.slug}/info`}
                title={page.name}
            />
            <main>
                <HtmlContent
                    content={page.html}
                    event={event}
                    pages={pages}
                    schedule={schedule}
                />
            </main>
        </PageWrapper>
    </IonPage>;
}

export default Page;