import React, {createContext} from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
// import {Navigate, Outlet, useParams} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import 'swiper/css';
import PageWrapper from '../../../components/PageWrapper';
import {getFullCalendar} from "../../../helper/ScheduleHelper";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ShortWeek from "../../../components/BigCalendar/ShortWeek";
import {CustomToolbar} from "../../../components/BigCalendar/CustomToolbar";
import {CustomEvent} from "../../../components/BigCalendar/CustomEvent";
import '../../../components/BigCalendar/BigCalendar.scss';
import {SingleEvent} from "../../../types/SingleEvent.type";
import {ScheduleItem as ScheduleItemProps} from "../../../types/ScheduleItem.type";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import PageHeader from "../../../components/PageHeader";
import HtmlContent from "../../../components/HtmlContent";

interface WebsiteCalendarProps extends EventContext, RouteComponentProps {
}

interface CalendarContextType {
    event: SingleEvent,
    resources: { id: string, title: string }[],
    tags: { slug: string, title: string, color: string }[],
}

export const CalendarContext = createContext<CalendarContextType | null>(null);

export const WebsiteCalendar: React.FC<WebsiteCalendarProps> = ({event, pages, schedule, match}) => {
    const { items, resources, tags } = getFullCalendar(event, schedule);
    const localizer = momentLocalizer(moment);
    const [selectedItem, setSelectedItem] = React.useState<ScheduleItemProps | null>(null);

    return <div>
        {selectedItem && <Modal
            open
            onClose={() => setSelectedItem(null)}
            className="WebsiteScheduleItem"
        >
            <ModalDialog
                sx={{
                    padding: 0,
                    overflow: 'hidden',
                    gap: 0,
                }}>
                <PageHeader
                    small
                    backgroundImage={selectedItem.image}
                    title={selectedItem.name}
                    info={`${moment(selectedItem.dateTimeFrom.date).format(('dd H:mm'))} - ${moment(selectedItem.dateTimeTill.date).format(('H:mm'))}  •  ${selectedItem.location.name}`}
                />
                <HtmlContent
                    content={selectedItem.description}
                    event={event}
                    schedule={schedule}
                    pages={pages}
                />
                <ModalClose
                    className="Close"
                />
            </ModalDialog>
        </Modal>}
        <PageWrapper className="IsEmbedded IsInfo FullHeight">
            <CalendarContext.Provider
                value={{
                    event: event,
                    resources: resources,
                    tags: tags
                }}
            >
            { /* @ts-ignore */}
            <Calendar
                className="Calendar"
                min={moment(event.startDateTime).set({'hour': 7, 'minute': 0}).toDate()}
                max={moment(event.endDateTime).set({'hour': 23, 'minute': 59}).toDate()}
                events={items}
                resources={resources}
                startAccessor="start"
                components={{
                    toolbar: CustomToolbar,
                    eventWrapper: CustomEvent,
                }}
                style={{ height: '100%' }}
                localizer={localizer}
                defaultDate={moment(event.startDateTime).toDate()}
                defaultView="day"
                // @ts-ignore
                views={{
                    day: true,
                    agenda: true,
                    week: ShortWeek
                }}
                event={event}
                // views={['month', 'day', 'agenda']}
                showMultiDayTimes={true}
                dayLayoutAlgorithm="no-overlap"
                endAccessor={({ end }) => {
                    if(end.getHours() + end.getMinutes() === 0) {
                        return new Date(end.getTime() - 1)
                    }

                    return end;
                }}
                onSelectEvent={(selected) => setSelectedItem(selected.details)}
            />
            </CalendarContext.Provider>

        </PageWrapper>
    </div>;
}

export default WebsiteCalendar;