import React, {ReactNode} from 'react';
import './BottomNavigation.scss'
import {NavLink} from "react-router-dom";

export type NavigationItem = {
    id: string;
    title: string;
    link: string;
    icon: ReactNode;
    isActive?: () => boolean;
}

type BottomNavigationProps = {
    items: NavigationItem[],
}

export const BottomNavigation = ({ items }: BottomNavigationProps) => {
    return <nav className="BottomNavigation">
        <ul>
            {items.map((item) => {
                return <li key={item.id}>
                    <NavLink to={item.link} isActive={item.isActive}>
                        <span className="NavIcon">{item.icon}</span>
                        <span className="NavTitle">{item.title}</span>
                    </NavLink>
                </li>
            })}
        </ul>
    </nav>
}

export default BottomNavigation;
