import React from 'react';
import './App.scss';
// import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Event from "./pages/Event";
import Events from "./pages/Events";
import InstallPrompt from "./components/InstallPrompt";
import {IonReactRouter} from "@ionic/react-router";
import {IonApp, IonRouterOutlet} from "@ionic/react";
import {Route} from "react-router";
import 'material-symbols/outlined.scss';

function App() {
    return (
        <IonApp>
            <div className="App">
                <InstallPrompt />
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path="/" component={Events} />
                        <Route path="/:eventSlug" component={Event} />
                    </IonRouterOutlet>
                </IonReactRouter>
            </div>
        </IonApp>
    );
}

export default App;
