import {TokenType} from "../../types/Token.type";
import {User} from "../../types/User.type";

const LocalStorageService = (function () {
  function setToken(tokenObj: TokenType) {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  }

  function setUser(userObj: User) {
    localStorage.setItem('user', JSON.stringify(userObj));
  }

  function setEvent(eventSlug: string) {
    localStorage.setItem('event', eventSlug);
  }

  function getEvent() {
    return localStorage.getItem('event');
  }

  function getAccessToken() {
    return localStorage.getItem('access_token');
  }

  function isLoggedIn() {
    return localStorage.getItem('user') !== null;
  }

  function getUser() {
    const user = localStorage.getItem('user');

    if (! isLoggedIn() || user === null) {
      return false;
    }

    return JSON.parse(user) as User;
  }

  function getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  function clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  }

  return {
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
    getUser,
    setUser,
    getEvent,
    setEvent,
    isLoggedIn,
  };
}());
export default LocalStorageService;
