import React from 'react';
import {Link} from "react-router-dom";
import {SingleEvent} from "../../types/SingleEvent.type";
import {moment} from "../../moment";
import {ScheduleItem, ScheduleItem as ScheduleItemProps} from "../../types/ScheduleItem.type";
import Card from "@mui/joy/Card";
import './ScheduleItemCard.scss';
import {Avatar, AvatarGroup} from '@mui/joy';
import tinycolor from 'tinycolor2';
import {sortHosts} from "../../helper/ScheduleHelper";

type ComponentProps = ScheduleItemProps & {
    event: SingleEvent,
    inline?: boolean,
    origin?: string,
    isEmbedded?: boolean,
    onClick?: (item: ScheduleItem) => void
}

export const ScheduleItemCard = ({event, inline, origin, onClick, ...item}: ComponentProps) => {
    const hosts = sortHosts(item.hosts);
    const date = moment(item.dateTimeFrom.date).format('YYYY-MM-DD');

    return <Card
        component={onClick ? 'a' : Link}
        className="ScheduleItemCard"
        to={{
            pathname: `/${event.slug}/item/${date}/${item.slug}/`,
            state: {
                origin: origin,
            },
        }}
        onClick={onClick ? () => onClick(item) : () => {}}
        variant="plain"
        sx={{
            boxShadow: 'md',
            marginBottom: '10px'
        }}>
        <span className="ScheduleItemCard_Tag" style={{
            backgroundColor: tinycolor(item.tag.color).lighten(20).toString(),
            color: tinycolor(item.tag.color).darken(30).toString()
        }}>
            {item.tag.display_name}
        </span>

        <span className="ScheduleItemCard_Name">
            {item.name}
        </span>

        <div className="ScheduleItemCard_Details">
            <div className="ScheduleItemCard_Time">
                {moment(item.dateTimeFrom.date).format(('H:mm'))} - {moment(item.dateTimeTill.date).format(('H:mm'))}
            </div>
            <div className="ScheduleItemCard_Location">
                {item.location.name}
            </div>
        </div>

        {hosts.length > 0 && <div className="ScheduleItemCard_Hosts">
            <span className="ScheduleItemCard_HostNames">
                {hosts.map((host) => host.name).join(', ')}
            </span>

            <AvatarGroup className="ScheduleItemCard_HostsAvatars" sx={{
                marginTop: 1
            }}>
                {hosts.map((host) => <Avatar alt={host.name} src={host.image}/>)}
            </AvatarGroup>
        </div>}
    </Card>
}

export default ScheduleItemCard;