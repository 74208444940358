import {Box, Button, ButtonGroup, IconButton, Stack, ToggleButtonGroup} from '@mui/joy';
import {Navigate as navigate} from 'react-big-calendar';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import { useWindowSize } from "@uidotdev/usehooks";
import Moment from "react-moment";
import React, {useContext} from "react";
import moment from "moment";
import {CalendarContext} from "../../pages/Event/Schedule/WebsiteCalendar";

// @ts-ignore
const CustomToolbar = ({ onView, onNavigate, label, view, date, ...props }) => {
    const size = useWindowSize();

    const info = useContext(CalendarContext);

    if(!info) {
        return null;
    }

    const goToDayView = () => onView('day');
    const goToWeekView = () => onView('week');
    const goToAgendaView = () => onView('agenda');
    const goToBack = () => onNavigate(navigate.PREVIOUS);
    const goToNext = () => onNavigate(navigate.NEXT);

    return (
        <>
            <div className="Toolbar">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    // flexDirection={(screenSize.width !== null && screenSize.width < 992) ? 'column' : 'row'}
                >
                    {view === 'day' && <> <Box
                        sx={{ flex: 1}}
                    >

                        <ButtonGroup>
                            <IconButton
                                onClick={goToBack}
                                disabled={moment(info.event.startDateTime).isSame(date, 'day')}
                            >
                                <ChevronLeftOutlinedIcon />
                            </IconButton>
                            <IconButton
                                onClick={goToNext}
                                disabled={moment(info.event.endDateTime).isSame(date, 'day')}
                            >
                                <ChevronRightOutlinedIcon />
                            </IconButton>
                        </ButtonGroup>
                    </Box>
                    <Box sx={{
                        flex: 1,
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        fontWeight: 'bold'
                    }}
                        // sx={{ marginTop: 1, marginBottom: 0.5, justifyContent: 'center' }}
                    >
                        <Moment date={date} locale="nl" format="dddd"/>
                    </Box>
                    </>}
                    <Box sx={{ flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        {size.width && size.width < 960 ? <ToggleButtonGroup value={view}>
                            <IconButton
                                onClick={goToDayView}
                                value="day"
                            >
                                <ViewDayOutlinedIcon />
                            </IconButton>
                            <IconButton
                                onClick={goToWeekView}
                                value="week"
                            >
                                <CalendarViewWeekOutlinedIcon />
                            </IconButton>
                            <IconButton
                                onClick={goToAgendaView}
                                value="agenda"
                            >
                                <ListOutlinedIcon />
                            </IconButton>
                        </ToggleButtonGroup> : <ToggleButtonGroup value={view}>
                            <Button
                                onClick={goToDayView}
                                startDecorator={<ViewDayOutlinedIcon />}
                                value="day"
                            >
                                Dag
                            </Button>
                            <Button
                                onClick={goToWeekView}
                                startDecorator={<CalendarViewWeekOutlinedIcon />}
                                value="week"
                            >
                                Event
                            </Button>
                            <Button
                                onClick={goToAgendaView}
                                startDecorator={<ListOutlinedIcon />}
                                value="agenda"
                            >
                                Lijst
                            </Button>
                        </ToggleButtonGroup>}

                        {/*<Filter*/}
                        {/*    tags={info.tags}*/}
                        {/*/>*/}
                    </Box>
                </Stack>
            </div>
        </>
    );
};

export { CustomToolbar };