import React from 'react';
import {SingleEvent} from "../../types/SingleEvent.type";
import {ScheduleItem} from "../../types/ScheduleItem.type";
import './ScheduleItemCard.scss';
import './UpcomingItemGroup.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import ScheduleItemCard from "./index";

type ComponentProps = { event: SingleEvent, items: ScheduleItem[], title: string, origin: string }

export const UpcomingItemGroup: React.FC<ComponentProps> = ({event, items, title, origin}) => {
    return <div className="UpcomingItemGroup">
        <h2 className="UpcomingItemGroup_Title">{title}</h2>
        <Swiper
            autoHeight
            spaceBetween={30}
            slidesPerView={1}
            className="UpcomingItemList"
            pagination={{
                dynamicBullets: true,
            }}
            modules={[Pagination]}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            }}
        >
            {items.map((item) => (
                <SwiperSlide key={item.token}>
                    <ScheduleItemCard
                        event={event}
                        {...item}
                        origin={origin}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
}

export default UpcomingItemGroup;