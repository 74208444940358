import React from 'react';
import './PageWrapper.scss'

type PageProps = {
    children: React.ReactNode,
    className?: string,
}

export const PageWrapper = ({children, className}: PageProps) => {
    return <div className={`PageWrapper ${className ? className : ''}`}>
        {children}
    </div>
}

export default PageWrapper;