import {useQuery} from "@tanstack/react-query";
import {SingleEvent} from "../types/SingleEvent.type";
import {handleInternalApiCall, METHOD_GET} from "../services/api-requests/ApiCallHandler";

export type GetEventQueryParams = {
    eventSlug: string;
}

const QUERY_KEY = 'event';

const fetchEvent = async (params: GetEventQueryParams): Promise<SingleEvent> => {
    const response = await handleInternalApiCall(
        `shop/event/${params.eventSlug}/info`,
        METHOD_GET,
        false,
        null
    );

    return response.data;
};

export const useGetEvent = (params: GetEventQueryParams) => {
    return useQuery<SingleEvent, Error>(useGetEventQuery(params));
};

export const useGetEventQuery = (params: GetEventQueryParams) => {
    return {
        queryKey: [QUERY_KEY],
        queryFn: () => fetchEvent(params)
    }
};