import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {QueryClient} from "@tanstack/react-query";
import moment from "moment";
import 'moment/locale/nl';
import 'material-symbols';
import theme from "./theme/theme";
import {
    CssVarsProvider as JoyCssVarsProvider,
    extendTheme as joyExtendTheme,
} from "@mui/joy/styles";
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {setupIonicReact} from "@ionic/react";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://dca1e9d6b48fb54a971d2f05d0d8a4ae@o320361.ingest.us.sentry.io/4507120646094848",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()
const materialTheme = materialExtendTheme(theme);
const joyTheme = joyExtendTheme(theme as any);


const persister = createSyncStoragePersister({
    storage: window.localStorage,
})

moment.locale('nl');

setupIonicReact();

root.render(
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <MaterialCssVarsProvider
            theme={{[MATERIAL_THEME_ID]: materialTheme}}
            defaultMode="light"
        >
            <JoyCssVarsProvider theme={joyTheme} defaultMode="light">
                <App />
            </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </PersistQueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
