import {Button} from "@mui/joy";
import {Link} from "react-router-dom";
import React from "react";
import {SingleEvent} from "../../types/SingleEvent.type";
import Card from "@mui/joy/Card";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import EmptyState from "../EmptyState";

type LoginPromptProps = {
    event: SingleEvent
}

export const LoginPrompt = ({event}: LoginPromptProps) => {

    return <Card
        className="LoginPrompt"
        variant="plain"
        sx={{
            boxShadow: 'md',
            marginBottom: '10px'
        }}
    >
        <EmptyState
            icon={<ConfirmationNumberOutlinedIcon/>}
            title="Log in"
            action={<Button
                color="primary"
                component={Link}
                to={`/${event.slug}/login`}
                variant="solid"
                sx={{
                    marginTop: '10px'
                }}
                fullWidth={true}
            >
                Log in
            </Button>}
        >
            Door in te loggen heb je je tickets makkelijk bij de hand.
        </EmptyState>
    </Card>;
}

export default LoginPrompt;