import React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import {Button, Chip, Stack, Typography} from "@mui/joy";
import {ReactComponent as AppleShare} from './apple-share.svg';
import './InstallPrompt.scss';
import {useAddToHomescreenPrompt} from "./useAddToHomescreenPrompt";
import {useCookies} from "react-cookie";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

function InstallPrompt() {
    const [promptable, promptToInstall, isInstalled] = useAddToHomescreenPrompt();
    const [cookies, setCookie] = useCookies(['ignore-install']);

    const UA = navigator.userAgent;

    const isInstallable = 'serviceWorker' in navigator;
    const isIos = UA.match(/iPhone|iPad|iPod/);
    const isAndroid = UA.match(/Android/);
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const isOpened = !!(isStandalone || (isIos && !UA.match(/Safari/)));

    if(!!cookies["ignore-install"]) {
        return null;
    }

    if(isInstalled) {
        return <Modal open={true} className="InstallPrompt">
            <ModalDialog
                layout="center"
                variant="plain"
                sx={{
                    bottom: '30px',
                    top: 'unset',
                    transform: 'translateX(-50%)',
                }}
            >
                {/*<ModalClose variant="plain" sx={{ m: 1 }} />*/}
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <img src="/logo-192x192.png" width="32px" height="32px" alt="App icon"/>
                        <span>Open de app</span>
                    </Stack>
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                    Yes, de app wordt geinstalleerd! Je kunt 'm terugvinden op je startscherm. <br />
                    Open de app om verder te gaan.
                </Typography>

                <Button
                    color="neutral"
                    onClick={() => setCookie('ignore-install', true)}
                    variant="outlined"
                >
                    Sluiten
                </Button>

            </ModalDialog>
        </Modal>;
    }

    if(isOpened || !isInstallable || isStandalone || (!isIos && !isAndroid) || (isAndroid && !promptable)) {
        return null;
    }

    return <Modal open={true} className="InstallPrompt">
        <ModalDialog
            layout="center"
            variant="plain"
            sx={{
                bottom: '30px',
                top: 'unset',
                transform: 'translateX(-50%)',
            }}
        >
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <img src="/logo-192x192.png" width="32px" height="32px" alt="App icon"/>
                    <span>Installeer de app</span>
                </Stack>
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
                Zo heb je het programma altijd makkelijk bij de hand.
            </Typography>

            {isIos && <>
                <Typography id="modal-desc" textColor="text.tertiary">
                    <Chip>1</Chip>&nbsp; Druk op <AppleShare />.
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary">
                    <Chip>2</Chip>&nbsp; Selecteer 'Zet op beginscherm'.
                </Typography>
            </>}

            {isAndroid && <>
                <Button
                    color="primary"
                    onClick={promptToInstall}
                    variant="solid"
                    sx={{
                        marginTop: '10px'
                    }}
                >
                    Installeer de app
                </Button>
            </>}
            <Button
                color="neutral"
                onClick={() => setCookie('ignore-install', true)}
                variant="outlined"
            >
                Ik wil de app niet installeren
            </Button>
        </ModalDialog>
    </Modal>;
}

export default InstallPrompt;
