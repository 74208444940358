import {Schedule} from "../types/Schedule.type";
import {moment} from "../moment";
import {ScheduleItem} from "../types/ScheduleItem.type";
import {Host} from "../types/Host.type";
import {Page} from "../types/Page.type";
import {SingleEvent} from "../types/SingleEvent.type";
import {Moment} from "moment/moment";

export const now = () => {
    // return moment('2024-04-28 17:00:00');

    return moment();
}

export const getSelectedDate = (event: SingleEvent, availableDates: Moment[], passedDate?: string) => {
    let selectedDate;

    if (passedDate !== undefined) {
        selectedDate = passedDate
    } else if (now().isBetween(moment(event.startDateTime), moment(event.endDateTime))) {
        selectedDate = now().format('YYYY-MM-DD')
    } else {
        selectedDate = availableDates[0].format('YYYY-MM-DD');
    }

    return selectedDate;
}

export const getUpcomingEvents = (schedule: Schedule) => {
    const scheduleItems = Object.values(schedule).flat(1);

    return sortScheduleItems(scheduleItems.filter((item) => {
        const dateTimeFrom = moment(item.dateTimeFrom.date);

        return dateTimeFrom.isSameOrAfter(now())
    })).filter((item) => {
        return item.tag.slug !== 'opening_hours';
    });
}

export const getUpcomingEventsForToday = (schedule: Schedule) => {
    const upcomingEvents = getUpcomingEvents(schedule);

    const eod = moment(now()).endOf('day');

    return upcomingEvents.filter((item) => {
        const dateTimeFrom = moment(item.dateTimeFrom.date);

        return dateTimeFrom.isBetween(
            now(),
            eod
        );
    });
};

export const getUpcomingEventsForNextHours = (schedule: Schedule) => {
    const upcomingEvents = getUpcomingEventsForToday(schedule);

    const twoHours = moment(now()).add(3, 'hours');

    return upcomingEvents.filter((item) => {
        const dateTimeFrom = moment(item.dateTimeFrom.date);

        return dateTimeFrom.isBetween(
            now(),
            twoHours
        );
    });
};

export const getOngoingEvents = (schedule: Schedule) => {
    const scheduleItems = Object.values(schedule).flat(1);

    return sortScheduleItems(scheduleItems.filter((item) => {
        const dateTimeFrom = moment(item.dateTimeFrom.date);
        const dateTimeEnd = moment(item.dateTimeTill.date);

        return now().isBetween(dateTimeFrom, dateTimeEnd)
    })).filter((item) => {
        return item.tag.slug !== 'opening_hours';
    });
}

export const sortScheduleItems = (items: ScheduleItem[]) => {
    return items.sort((a, b) => {
        return moment(a.dateTimeFrom.date).diff(b.dateTimeFrom.date);
    });
}

export const sortDates = (items: moment.Moment[]) => {
    return items.sort((a, b) => {
        return moment(a).diff(b);
    });
}

export const sortHosts = (hosts: Host[]) => {
    const sorted = hosts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    const tags = ['speaker', 'seminar_speaker', 'artist', 'band']
    const mapper = new Map(tags.map((v, i) => [v, i + 1]))

    return sorted.sort((a, b) => {
        return (mapper.get(a.tag.slug) || Infinity) - (mapper.get(b.tag.slug) || Infinity)
    })
}

export const getItemsByHost = (schedule: Schedule, hostToken: string) => {
    let filteredItems: Schedule = {};

    Object.keys(schedule).forEach((date: string) => {
        const filtered =schedule[date].filter((item: ScheduleItem) => {
            return !!item.hosts.find((host) => host.token === hostToken)
        });

        if(filtered.length > 0) {
            filteredItems[date] = filtered;
        }
    });

    return filteredItems;
}

export const getItemsByLocation = (schedule: Schedule, locationToken: string) => {
    let filteredItems: Schedule = {};

    Object.keys(schedule).forEach((date: string) => {
        const filtered = schedule[date].filter((item: ScheduleItem) => {
            return item.location.token === locationToken;
        });

        if(filtered.length > 0) {
            filteredItems[date] = filtered;
        }
    });

    return filteredItems;
}

export const getItemsByTag = (schedule: Schedule, tag: string) => {
    let filteredItems: Schedule = {};

    Object.keys(schedule).forEach((date: string) => {
        const filtered = schedule[date].filter((item: ScheduleItem) => {
            return item.tag.slug === tag;
        });

        if(filtered.length > 0) {
            filteredItems[date] = filtered;
        }
    });

    return filteredItems;
}


export const getFullCalendar = (event: SingleEvent, schedule: Schedule) => {
    const scheduleItems = Object.values(schedule).flat(1);
    let resources: { [key: string]: { id: string, title: string } } = {};
    let tags: { [key: string]: { slug: string, title: string, color: string } } = {};

    const items = scheduleItems.map((item: ScheduleItem) => {
        const date = moment(item.dateTimeFrom.date).format('YYYY-MM-DD');

        const location = {
            id: item.location.token,
            title: item.location.name,
        };

        const tag = {
            slug: item.tag.slug,
            title: item.tag.display_name,
            color: item.tag.color,
        };

        resources[item.location.token] = location;

        tags[item.tag.slug] = tag;

        return {
            id: item.token,
            title: item.name,
            start: moment(item.dateTimeFrom.date).toDate(),
            end: moment(item.dateTimeTill.date).toDate(),
            url: `/${event.slug}/item/${date}/${item.slug}/`,
            resourceId: item.location.token,
            location: location,
            tag: tag,
            details: item,
        }
    });

    return {
        items: items,
        resources: Object.values(resources).sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
        tags: Object.values(tags).sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
    };
};

export const getImages = (schedule: Schedule | undefined, pages: Page[] | undefined) => {
    if(schedule === undefined || pages === undefined) {
        return [];
    }

    let images: string[] = [];
    const scheduleItems = Object.values(schedule).flat(1);

    scheduleItems.forEach((item: ScheduleItem) => {
        images.push(item.image);

        item.hosts.forEach((host: Host) => {
            images.push(host.image);
        });
    });

    pages.forEach((page: Page) => {
        images.push(page.image);
    });

    return images.filter((value, index, array) => array.indexOf(value) === index);
}