import React from 'react';
import './PageHeader.scss'
import {Link} from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import RefreshIcon from '@mui/icons-material/Refresh';
import {AspectRatio} from "@mui/joy";

type PageHeaderProps = {
    backgroundImage?: string,
    backLink?: string,
    title: string,
    info?: string,
    small?: boolean,
    refreshAction?: () => void,
    disableRefresh?: boolean,
}

export const PageHeader = ({backgroundImage, backLink, title, info, small, refreshAction, disableRefresh}: PageHeaderProps) => {
    if(small) {
        return <AspectRatio
            ratio="16/9"
            maxHeight={200}
        >
            <header
                className="PageHeader PageHeader_Small"
                style={{
                    ...backgroundImage && {
                        backgroundImage: `url(${backgroundImage})`
                    }
                }}
            >
                <div className="PageHeader_Overlay"></div>
                <div className="PageHeader_Bar">
                    <div className="PageHeader_Bar_Overlay"></div>
                    <div className="PageHeader_Buttons">
                        {backLink && <div className="PageHeader_Back">
                            <Link to={backLink}>
                                <ArrowBackOutlinedIcon/>
                            </Link>
                        </div>}
                        {refreshAction && <div className="PageHeader_Refresh">
                            <button onClick={refreshAction} disabled={disableRefresh}>
                                <RefreshIcon/>
                            </button>
                        </div>}
                    </div>
                </div>
                <div className="PageHeader_Content">
                    <div className="PageHeader_Title">
                        {title}
                    </div>
                    {info && <div className="PageHeader_Info">
                        {info}
                    </div>}
                </div>
            </header>
        </AspectRatio>
    }

    return <AspectRatio
        ratio="4/3"
        maxHeight={350}
    >
        <header
            className="PageHeader"
            style={{
                ...backgroundImage && {
                    backgroundImage: `url(${backgroundImage})`
                }
            }}
        >
            <div className="PageHeader_Overlay"></div>
            <div className="PageHeader_Bar">
                <div className="PageHeader_Bar_Overlay"></div>
                {backLink && <div className="PageHeader_Buttons">
                    <div className="PageHeader_Back">
                        <Link to={backLink}>
                            <ArrowBackOutlinedIcon/>
                        </Link>
                    </div>
                </div>}
            </div>
            <div className="PageHeader_Content">
                <div className="PageHeader_Title">
                    {title}
                </div>
                {info && <div className="PageHeader_Info">
                    {info}
                </div>}
            </div>
        </header>
    </AspectRatio>
}

export default PageHeader;