import {useQuery} from "@tanstack/react-query";
import {Page} from "../types/Page.type";
import {handleInternalApiCall, METHOD_GET} from "../services/api-requests/ApiCallHandler";

export type Pages = Page[];

const QUERY_KEY = 'pages';

const fetchPages = async (eventToken: string): Promise<Pages> => {
    const response = await handleInternalApiCall(
        `event/${eventToken}/media/pages`,
        METHOD_GET,
        false,
        null
    );

    return response.data;
};

export const useGetPages = (eventToken: string) => {
    return useQuery<Pages, Error>(useGetPagesQuery(eventToken));
};

export const useGetPagesQuery = (eventToken: string, enabled?: boolean) => {
    return {
        queryKey: [`${eventToken}_${QUERY_KEY}`],
        queryFn: () => fetchPages(eventToken),
        enabled: !!enabled
    };
};