export const trackInMatomo = (data) => {
  // eslint-disable-next-line no-underscore-dangle
  const _paq = window._paq || [];
  _paq.push(data);
};

export const trackPageView = (location) => {
  // Matomo
  trackInMatomo(['setCustomUrl', location]);
  trackInMatomo(['setDocumentTitle', document.title]);
  trackInMatomo(['trackPageView']);
};