import {useQuery} from "@tanstack/react-query";
import {Schedule} from "../types/Schedule.type";
import {handleInternalApiCall, METHOD_GET} from "../services/api-requests/ApiCallHandler";

const QUERY_KEY = 'schedule';

const fetchSchedule = async (eventToken: string): Promise<Schedule> => {
    const response = await handleInternalApiCall(
        `event/${eventToken}/schedule/items`,
        METHOD_GET,
        false,
        null
    );

    return response.data;
};

export const useGetSchedule = (eventToken: string) => {
    return useQuery<Schedule, Error>(useGetScheduleQuery(eventToken));
};

export const useGetScheduleQuery = (eventToken: string, enabled?: boolean) => {
    return {
        queryKey: [`${eventToken}_${QUERY_KEY}`],
        queryFn: () => fetchSchedule(eventToken),
        enabled: !!enabled
    };
};