import React, {useEffect} from 'react';
import ScheduleIcon from "@mui/icons-material/Schedule";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BottomNavigation from "../../components/Navigation/BottomNavigation";
import '../../scss/index.scss';
import {useGetScheduleQuery} from "../../hooks/useGetSchedule";
import {FullScreenLoader} from "../../components/FullScreenLoader";
import {GetEventQueryParams, useGetEvent} from "../../hooks/useGetEvent";
import {useQueries} from "@tanstack/react-query";
import {Pages as PagesType, useGetPagesQuery} from "../../hooks/useGetPages";
import {SingleEvent} from "../../types/SingleEvent.type";
import Upcoming from "./Upcoming";
import Page from "./Pages/Page";
import {IonPage, IonRouterOutlet} from "@ionic/react";
import {matchPath, Redirect, Route, RouteComponentProps, useLocation} from "react-router";
import Schedule from "./Schedule/Schedule";
import Pages from "./Pages/Pages";
import {Schedule as ScheduleType} from "../../types/Schedule.type";
import ScheduleItem from "./Schedule/ScheduleItem";
import Index from "./Profile";
import Login from "./Login";
import queryString from "query-string";
import LocalStorageService from "../../services/storage/LocalStorageService";
import {logInUserByToken} from "../../helper/SessionHelper";
import Host from "./Schedule/Host";
import WebsiteSchedule from "./Schedule/WebsiteSchedule";
import {getImages} from "../../helper/ScheduleHelper";
import useImagePreloader from "../../hooks/useImagePreloader";
import Error from "../Error";
import WebsiteCalendar from "./Schedule/WebsiteCalendar";
import {trackPageView} from "../../services/tracking/TrackingHandler";
import {useWindowSize} from "@uidotdev/usehooks";
import SideNavigation from "../../components/Navigation/SideNavigation";
import classNames from "classnames";
import InfoDisplay from "./InfoDisplay";

export type EventContext = {
    event: SingleEvent,
    schedule: ScheduleType,
    pages: PagesType
};

interface EventProps
    extends RouteComponentProps<{
        eventSlug: string;
    }> {
}


export const Event: React.FC<EventProps> = ({match, location}) => {
    const currentLocation = useLocation();

    useEffect(() => {
        trackPageView(window.location.href);
    }, [currentLocation]);

    const {eventSlug} = match.params;

    const {
        data: event,
        isLoading: isEventLoading,
        isError: isEventError
    } = useGetEvent({eventSlug} as GetEventQueryParams);

    const token = event?.token;

    const results = useQueries({
        queries: [
            useGetScheduleQuery(token ? token : '', !!token),
            useGetPagesQuery(token ? token : '', !!token)
        ]
    });

    const isLoading = isEventLoading || results.some((query) => query.isLoading);
    const isError = isEventError || results.some((query) => query.isError);

    const schedule = results[0].data;
    const pages = results[1].data;

    const size = useWindowSize();

    const { imagesPreloaded } = useImagePreloader([
        event ? event.heroImage : '',
        'https://site.assets.soulsurvivor.nl/uploads/2023/12/12135253/card_info-scaled.jpg',
        'https://site.assets.soulsurvivor.nl/uploads/2024/04/21120442/app-header-1.jpg',
        'https://site.assets.soulsurvivor.nl/uploads/2024/04/06124827/52894426604_01c81519a7_k.jpg',
        ...getImages(schedule, pages)
    ]);

    if (isLoading) return (<FullScreenLoader/>)
    if (isError) return (<Error />)

    if (event === undefined || pages === undefined || schedule === undefined || ! imagesPreloaded) {
        return <FullScreenLoader/>;
    }

    const urlParams = queryString.parse(window.location.search);

    if (typeof urlParams.key !== 'undefined') {
        if (!LocalStorageService.isLoggedIn()) {
            logInUserByToken(urlParams.key as string, event.slug, window.location.pathname);
            return <FullScreenLoader/>;
        }
    }

    const isInfo = !!matchPath(location.pathname,`/${eventSlug}/site`);
    const isEmbedded = typeof urlParams.embedded !== 'undefined';
    const shouldShowNavigation = !isEmbedded && !isInfo;
    const shouldShowDesktopNavigation = shouldShowNavigation && size.width && size.width > 1024;
    const shouldShowMobileNavigation = shouldShowNavigation && !shouldShowDesktopNavigation;

    const navBarItems = [
        {
            id: 'upcoming',
            title: 'Nu & straks',
            link: `/${eventSlug}/upcoming`,
            icon: <ScheduleIcon/>,
            isActive: () => {
                return !!matchPath(
                    window.location.pathname,
                    `/${eventSlug}/upcoming`
                )
            }
        },
        {
            id: 'schedule',
            title: 'Programma',
            link: `/${eventSlug}/schedule/`,
            icon: <EventNoteOutlinedIcon/>,
            isActive: () => {
                return !!matchPath(
                    window.location.pathname,
                    `/${eventSlug}/schedule`
                ) || !!matchPath(
                    window.location.pathname,
                    `/${eventSlug}/item`
                )
            }
        },
        {
            id: 'info',
            title: 'Info',
            link: `/${eventSlug}/info`,
            icon: <InfoOutlinedIcon/>,
            isActive: () => {
                return !!matchPath(
                    window.location.pathname,
                    `/${eventSlug}/info`
                )
            }
        },
        {
            id: 'profile',
            title: 'Profiel',
            link: `/${eventSlug}/profile`,
            icon: <AccountCircleOutlinedIcon/>,
            isActive: () => {
                return !!matchPath(
                    window.location.pathname,
                    `/${eventSlug}/login`
                ) || !!matchPath(
                    window.location.pathname,
                    `/${eventSlug}/profile`
                )
            }
        },
    ];

    return <IonPage
        className={
            classNames({
                'Desktop': shouldShowDesktopNavigation,
                'Mobile' : shouldShowMobileNavigation
           })
        }
    >
        <IonRouterOutlet animated={!isEmbedded && !isInfo}>
            <Route exact path="/:eventSlug" render={({match}) => (
                <Redirect to={`/${match.params.eventSlug}/upcoming`}/>
            )}/>
            <Route exact path="/:eventSlug/upcoming" render={(props) => (
                <Upcoming event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route exact path="/:eventSlug/item/:date/:itemSlug" render={(props) => (
                <ScheduleItem event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route exact path="/:eventSlug/item/:date/:itemSlug/:hostSlug" render={(props) => (
                <Host event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route path="/:eventSlug/schedule/:date?" render={(props) => (
                <Schedule event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route path="/:eventSlug/site/schedule/:date?/:itemSlug?" render={(props) => (
                <WebsiteSchedule event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route path="/:eventSlug/site/calendar" render={(props) => (
                <WebsiteCalendar event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route path="/:eventSlug/site/info-display" render={(props) => (
                <InfoDisplay event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route exact path="/:eventSlug/info" render={(props) => (
                <Pages event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route path="/:eventSlug/info/:pageSlug" render={(props) => (
                <Page event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route exact path="/:eventSlug/login" render={(props) => (
                <Login event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
            <Route path="/:eventSlug/profile" render={(props) => (
                <Index event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
        </IonRouterOutlet>

        {shouldShowDesktopNavigation && <SideNavigation items={navBarItems} />}
        {shouldShowMobileNavigation && <BottomNavigation items={navBarItems} />}
    </IonPage>
}

export default Event;