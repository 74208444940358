import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
import {IonPage, IonRouterOutlet} from "@ionic/react";
import LocalStorageService from "../../../services/storage/LocalStorageService";
import {useQueries} from "@tanstack/react-query";
import {useGetTicketsQuery} from "../../../hooks/useGetTickets";
import {useGetOrdersQuery} from "../../../hooks/useGetOrders";
import FullScreenLoader from "../../../components/FullScreenLoader";
import {SingleTicket} from "../../../types/SingleTicket.type";
import {OrderListItem} from "../../../types/SingleOrder.type";
import {Route} from "react-router";
import Tickets from "./Tickets";
import ProfileMenu from "./ProfileMenu";
import {useGetOrderTicketsQuery} from "../../../hooks/useGetOrderTickets";
import Error from "../../Error";

export interface ProfileContext extends EventContext {
    tickets: SingleTicket[],
    orders: OrderListItem[],
    orderTickets: SingleTicket[],
}

interface ProfileProps extends EventContext {
}

export const Profile: React.FC<ProfileProps> = ({event, pages, schedule}) => {
    const isLoggedIn = LocalStorageService.isLoggedIn();

    const results = useQueries({
        queries: [
            useGetTicketsQuery(event.token, isLoggedIn),
            useGetOrdersQuery(event.token, isLoggedIn),
            useGetOrderTicketsQuery(event.token, isLoggedIn),
        ]
    });

    const isLoading = results.some((query) => query.isLoading);
    const isFetching = results.some((query) => query.isFetching);
    const isError = results.some((query) => query.isError);

    if (isLoading) return (<FullScreenLoader/>)
    if (isError) return (<Error />)

    const tickets = results[0].data ?? [];
    const orders = results[1].data ?? [];
    const orderTickets = results[2].data ?? [];

    return <IonPage>
        <IonRouterOutlet>
            <Route exact path="/:eventSlug/profile" render={(props) => (
                <ProfileMenu event={event} schedule={schedule} pages={pages} {...props} />
            )}/>

            <Route exact path="/:eventSlug/profile/tickets" render={(props) => (
                <Tickets isFetching={isFetching} tickets={tickets} orders={orders} orderTickets={orderTickets} event={event} schedule={schedule} pages={pages} {...props} />
            )}/>
        </IonRouterOutlet>
    </IonPage>;
}

export default Profile;