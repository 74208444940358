import * as React from "react";
import parse from "html-react-parser";
import './HtmlContent.scss'
import {EventContext} from "../../pages/Event";
import InlineSchedule from "./InlineSchedule";
import InlineCard from "./InlineCard";
import InlineAlert from "./InlineAlert";
import Divider from "@mui/joy/Divider";

interface HtmlContentProps extends EventContext {
    content: string
}

const shortcodeTypeRegex = /\[([^/ ]+)/;
// const shortcodeTypeRegex = /
const attributesRegex = /[\w-]+=".*?"/g;

// @ts-ignore
const handleShortcodes = (node, event, schedule, pages) => {
    // It seems all shortcodes are P tags.
    // So we check that the node has a type, and that that type
    // is a tag.
    if (node.type && node.type === "tag") {
        // If this tag has children, and it is the first child.
        // This may cause issues, but I have not had issues with it.
        // The first array is usually a shortcode is the first and
        // only child of a tag node.
        const shortcode = node.children[0]?.data;

        if (!shortcode) {
            return node;
        }

        let attributes: { [key: string]: string } = {};
        const shortcodeTypeMatches = shortcodeTypeRegex.exec(shortcode);
        const shortcodeType = shortcodeTypeMatches ? shortcodeTypeMatches[1] : null;

        const attributeMatches = shortcode.match(attributesRegex);

        if (shortcodeType && attributeMatches) {
            attributeMatches.forEach(function (match: string) {

                const attribute = match.match(/([\w-]+)="(.*?)"/);
                if (attribute) {
                    attributes[attribute[1]] = attribute[2];
                }
            });

            if (shortcodeType === 'card') {
                return <InlineCard
                    event={event}
                    schedule={schedule}
                    title={attributes.title}
                    content={attributes.content}
                    action={attributes.action}
                    link={attributes.link}
                />;
            }

            if (shortcodeType === 'banner') {
                return <InlineAlert
                    event={event}
                    schedule={schedule}
                    content={attributes.content}
                    action={attributes.action}
                    link={attributes.link}
                />;
            }

            if (shortcodeType === 'schedule') {
                return <>
                    <Divider sx={{
                        marginTop: -2,
                        marginBottom: 4,
                    }}/>
                    <InlineSchedule
                        event={event}
                        schedule={schedule}
                        location={attributes.location}
                        tag={attributes.tag}
                        host={attributes.host}
                    />
                </>;
            }

            if (shortcodeType === 'opening') {
                return <>
                    <Divider sx={{
                        marginTop: -2,
                        marginBottom: 4,
                    }}/>
                    <InlineSchedule
                        title="Openingstijden"
                        event={event}
                        schedule={schedule}
                        location={attributes.location}
                    />
                </>;
            }
        }
    }

    // If nothing then return original node
    return node;
};


const HtmlContent = ({content, event, schedule, pages}: HtmlContentProps) => {
    const reactElements = parse(content || "", {
        replace: (domNode => handleShortcodes(domNode, event, schedule, pages)),
    });

    return <div className="HtmlContent">
        {reactElements}
    </div>;
};

export default HtmlContent;