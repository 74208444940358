import React from 'react';
import {SingleEvent} from "../../types/SingleEvent.type";
import './ScheduleItemCard.scss';
import './UpcomingItemGroup.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import moment from "moment";
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Moment from "react-moment";
import EmptyState from "../EmptyState";
import {now} from "../../helper/ScheduleHelper";

type UpcomingItemGroupProps = { event: SingleEvent }

export const UpcomingItemGroup: React.FC<UpcomingItemGroupProps> = ({event}) => {
    const currentHour = now().hours();

    if (now().isBefore(moment(event.startDateTime))) {
        return <EmptyState
            icon={<WatchLaterOutlinedIcon/>}
            title="Nog even wachten..."
        >
            {event.name} begint op{' '}
            <Moment date={event.startDateTime} locale="nl" format="D MMMM"/>{' '}
            om{' '}
            <Moment date={event.startDateTime} locale="nl" format="HH:mm"/>
        </EmptyState>
    } else if (now().isAfter(moment(event.endDateTime))) {
        return <EmptyState
            icon={<WavingHandOutlinedIcon/>}
            title="Helaas..."
        >
            {event.name} is alweer afgelopen
        </EmptyState>
    }

    if (currentHour >= 0 && currentHour < 6) {
        return <EmptyState
            icon={<BedtimeOutlinedIcon/>}
            title="Welterusten!"
        >
            Er staat voor vandaag niets meer op het programma
        </EmptyState>;
    } else if (currentHour >= 6 && currentHour < 12)  {
        return <EmptyState
            icon={<CalendarTodayOutlinedIcon/>}
            title="Goedemorgen!"
        >
            Er staat de komende uren niets op het programma
        </EmptyState>;
    } else if (currentHour >= 12 && currentHour < 18)  {
        return <EmptyState
            icon={<CalendarTodayOutlinedIcon/>}
            title="Goedemiddag!"
        >
            Er staat de komende uren niets op het programma
        </EmptyState>;
    } else {
        return <EmptyState
            icon={<CalendarTodayOutlinedIcon/>}
            title="Goedenavond!"
        >
            Er staat de komende uren niets op het programma
        </EmptyState>;
    }

}

export default UpcomingItemGroup;