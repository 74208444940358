import LocalStorageService from '../services/storage/LocalStorageService';
import {logError} from './ErrorHandler';
import {handleApiCall, METHOD_GET, METHOD_POST} from '../services/api-requests/ApiCallHandler';
import {AxiosError, isAxiosError} from "axios";
import {ApiError} from "../types/ApiError.type";

export function logInUserByToken(token: string, eventSlug: string, path: string) {
    LocalStorageService.clearToken();

    handleApiCall('/login/key', METHOD_POST, false, {
        key: token,
    }).then((response) => {
        LocalStorageService.setToken({
            access_token: response.data.token,
            refresh_token: response.data.refreshToken,
        });

        handleApiCall('/me', METHOD_GET, true, null).then((response) => {
            LocalStorageService.setUser(response.data);
            LocalStorageService.setEvent(eventSlug);
            window.location.href = path;
        }).catch((error) => {
            logError(error);
        })
    }).catch((error) => {
        logError(error);

        if (!error.response || !error.response.data) {
            return false;
        }

        if (isAxiosError<ApiError>(error) && error.response.status === 401) {
            if (error.response.data.error === 910) {
                window.location.href = `/${eventSlug}/login?e=key_invalid`;
            } else if (error.response.data && error.response.data.error === 911) {
                window.location.href = `/${eventSlug}/login?e=key_expired`;
            }
        }

        return false;
    });
}

export function logInUserByUsernameAndPassword(
    username: string,
    password: string,
    eventSlug: string,
    onSuccess: () => void,
    onError: (error: AxiosError<ApiError>) => void
) {
    LocalStorageService.clearToken();

    handleApiCall('/login', METHOD_POST, false, {
        username,
        password,
    }).then((response) => {
        LocalStorageService.setToken({
            access_token: response.data.token,
            refresh_token: response.data.refreshToken,
        });

        handleApiCall('/me', METHOD_GET, true, null)
            .then((response) => {
                LocalStorageService.setUser(response.data);
                LocalStorageService.setEvent(eventSlug);
            })
            .then(onSuccess)
            .catch((error) => {
                logError(error);
            });
    }).catch((error) => {
        onError(error);
        return false;
    });
}

export function logout() {
    LocalStorageService.clearToken();
    window.location.href = '/';
}
