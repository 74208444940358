import React, {useEffect} from 'react';
import '../../../scss/index.scss';
import ScheduleItemCard from "../../../components/ScheduleItemCard";
import {EventContext} from "../index";
import {now, sortScheduleItems} from "../../../helper/ScheduleHelper";
import {ScheduleItem} from "../../../types/ScheduleItem.type";
import {moment} from "../../../moment";
import classNames from "classnames";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {useSwiperSlide} from 'swiper/react';

interface SchedulePageProps extends EventContext {
    date: string;
    isEmbedded?: boolean;
    onItemClick?: (item: ScheduleItem) => void,
    container?: React.RefObject<HTMLDivElement>,
}

export const SchedulePage: React.FC<SchedulePageProps> = ({event, pages, schedule, date, isEmbedded, onItemClick, container}) => {
    const items = schedule[date] ?? [];
    const isToday = date === now().format('YYYY-MM-DD');
    const swiperSlide = useSwiperSlide();

    gsap.registerPlugin(useGSAP);
    gsap.registerPlugin(ScrollToPlugin);

    const sortedItems = sortScheduleItems(items).filter((item) => {
        return item.tag.slug !== 'opening_hours';
    });

    const past = sortedItems.filter((item) => {
        const dateTimeEnd = moment(item.dateTimeTill.date);

        return now().isAfter(dateTimeEnd)
    });

    const ongoing = sortedItems.filter((item) => {
        const dateTimeFrom = moment(item.dateTimeFrom.date);
        const dateTimeEnd = moment(item.dateTimeTill.date);

        return now().isBetween(dateTimeFrom, dateTimeEnd)
    });

    const upcoming = sortedItems.filter((item) => {
        const dateTimeFrom = moment(item.dateTimeFrom.date);

        return now().isBefore(dateTimeFrom)
    });

    useEffect(() => {
        if(container && isToday && swiperSlide.isActive) {
            gsap.to(container.current, {
                scrollTo: {
                    y: `.isToday .SchedulePage_Ongoing`,
                    offsetY: 10,
                },
            });
        }
    }, [container, swiperSlide, isToday]);

    return <div className={classNames({
        hasDateNavigation: true,
        isToday: isToday
    })}
    >
        <div className="SchedulePage_Group SchedulePage_Past">
            {past.map((item) => {
                return <ScheduleItemCard
                    key={item.token}
                    event={event}
                    isEmbedded={isEmbedded}
                    onClick={onItemClick}
                    {...item}
                />
            })}
        </div>
        <div className="SchedulePage_Group SchedulePage_Ongoing">
            {ongoing.map((item) => {
                return <ScheduleItemCard
                    key={item.token}
                    event={event}
                    isEmbedded={isEmbedded}
                    onClick={onItemClick}
                    {...item}
                />
            })}
        </div>
        <div className="SchedulePage_Group SchedulePage_Upcoming">
            {upcoming.map((item) => {
                return <ScheduleItemCard
                    key={item.token}
                    event={event}
                    isEmbedded={isEmbedded}
                    onClick={onItemClick}
                    {...item}
                />
            })}
        </div>
    </div>;
}

export default SchedulePage;