import * as React from "react";
import Icon from '@mui/material/Icon';

type IconPresentationProps = {
    glyph: string,
    className?: string
}

function cameltoSnakeCase(text = "") {
    return text
        .replace(/([A-Z])/g, "_$1")
        .toLowerCase()
        .slice(1);
}

const IconPresentation = ({ glyph, className }: IconPresentationProps) => {
    if (!glyph) return null;
    return <Icon baseClassName="material-symbols-outlined" className={className}>{cameltoSnakeCase(glyph?.toString())}</Icon>;
};

export default IconPresentation;