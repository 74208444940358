import React from 'react';
import '../../../scss/index.scss';
import {EventContext} from "../index";
import {FullScreenLoader} from "../../../components/FullScreenLoader";
import {moment} from "../../../moment";
import PageHeader from "../../../components/PageHeader";
import {RouteComponentProps, useLocation} from "react-router";
import {IonPage} from "@ionic/react";
import PageWrapper from "../../../components/PageWrapper";
import {Host as HostType} from "../../../types/Host.type";
import 'swiper/css';
import 'swiper/css/pagination';
import './ScheduleItem.scss';
import HtmlContent from "../../../components/HtmlContent";
import {Avatar, List, ListDivider, ListItemButton, ListItemContent, ListItemDecorator} from '@mui/joy';
import ListItem from "@mui/joy/ListItem";
import {Link} from "react-router-dom";
import {KeyboardArrowRight} from "@mui/icons-material";
import {sortHosts} from "../../../helper/ScheduleHelper";
import Error from "../../Error";

interface ScheduleItemProps extends EventContext, RouteComponentProps<{
    date: string;
    itemSlug: string;
}> {
    isEmbedded?: boolean,
}

interface locationState {
    origin?: string
}

export const ScheduleItem: React.FC<ScheduleItemProps> = ({event, pages, schedule, match}) => {
    const {date, itemSlug} = match.params;

    const { state } = useLocation<locationState>();

    const origin = (state && state.origin) ? state.origin: undefined;

    if (event === null || schedule === null || date === undefined || itemSlug === undefined) {
        return <FullScreenLoader/>;
    }

    const items = schedule[date] ?? [];
    const item = items.find((item) => item.slug === itemSlug);

    if (item === undefined) {
        return <Error />;
    }

    const hosts = sortHosts(item.hosts);

    return <IonPage>
        <PageWrapper>
            <PageHeader
                backgroundImage={item.image}
                backLink={origin ?? `/${event.slug}/schedule/${date}`}
                title={item.name}
                info={`${moment(item.dateTimeFrom.date).format(('dd H:mm'))} - ${moment(item.dateTimeTill.date).format(('H:mm'))}  •  ${item.location.name}`}
            />
            <main>
                <HtmlContent
                    content={item.description}
                    event={event}
                    schedule={schedule}
                    pages={pages}
                />

                {item.hosts.length > 0 && <List
                    variant="plain"
                    sx={{
                        marginTop: 4,
                        boxShadow: 'md',
                        marginBottom: '10px',
                        borderRadius: 'sm',
                    }}
                    className="Hosts"
                >
                    {hosts.map((host: HostType, index: number) => (
                        <>
                            <ListItem>
                                <ListItemButton
                                    component={Link}
                                    to={`/${event.slug}/item/${date}/${itemSlug}/${host.slug}`}
                                >
                                    <ListItemDecorator>
                                        <Avatar size="sm" src={host.image}/>
                                    </ListItemDecorator>
                                    <ListItemContent>
                                        {host.name}
                                    </ListItemContent>
                                    <KeyboardArrowRight/>
                                </ListItemButton>
                            </ListItem>
                            {index !== (hosts.length - 1) && <ListDivider inset="gutter"/>}
                        </>
                    ))}
                </List>}
            </main>
        </PageWrapper>
    </IonPage>;
}

export default ScheduleItem;